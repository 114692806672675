import React, { useState, useRef, useEffect } from "react";
import Manual from "./manual";
import { DAppProps, DAppState } from "./types/dApp";
import CSV from "./csv";
import { AirDropper } from "./lib/air-dropper";
// import Image from "next/image";

const DApp = (props?: DAppProps) => {
  const [dapp, setDapp] = useState<DAppState>({
    airDropper: undefined,
    address: undefined,
    screen: props?.screen || "manual",
  });

  useEffect(() => {
    const fetchWallet = async () => {
      const airDropper = new AirDropper();
      const activeAccount = await airDropper.getActiveAccount();

      if (activeAccount) {
        setDapp({
          ...dapp,
          airDropper: airDropper,
          address: activeAccount.address,
        });
      }
    };
    fetchWallet();
  }, []);

  const toggleWallet = async () => {
    if (!dapp.address) {
      const airDropper = new AirDropper();
      const address = await airDropper.setWalletProvider();

      setDapp({
        ...dapp,
        airDropper: airDropper,
        address: address,
      });
    } else {
      setDapp({
        ...dapp,
        airDropper: undefined,
        address: undefined,
      });
      disconnectWallet();
    }
  };

  const disconnectWallet = async () => {
    if (dapp.address) {
      const airDropper = new AirDropper();
      await airDropper.disconnectWallet();
      setDapp({
        ...dapp,
        airDropper: undefined,
        address: undefined,
      });
    }
  };

  return (
    <div className="flex-row">
      {dapp.screen === "manual" && (
        <Manual airDropper={dapp.airDropper} address={dapp.address} toggleWallet={toggleWallet} />
      )}

      {dapp.screen === "csv" && (
        <CSV airDropper={dapp.airDropper} address={dapp.address} toggleWallet={toggleWallet} />
      )}
    </div>
  );
};

export default DApp;