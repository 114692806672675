import { TransferParamType, TxType } from "../types/air-dropper";
import { TransferParams } from "../types/data";

export const parseCSV = (csv: string[][]): Record<string, TransferParamType[]> => {
    let transferParams = new Map<string, TransferParams[]>();
    for (let i = 0; i < csv.length; i++) {
        if (csv[i].length !== 4)
            continue;

        if (transferParams.has(csv[i][1]) === false) {
            transferParams.set(csv[i][1], []);
        }
        transferParams.get(csv[i][1])!.push({
            from_: csv[i][0],
            to_: csv[i][3],
            token_id: parseInt(csv[i][2]),
            amount: 1
        });
    }

    let fa2TransferParams: Record<string, TransferParamType[]> = {};
    transferParams.forEach((txs, contractAddress) => {
        let fromSorted = new Map<string, TxType[]>();
        txs.forEach(tx => {
            if (fromSorted.has(tx.from_) === false) {
                fromSorted.set(tx.from_, []);
            }
            fromSorted.get(tx.from_)?.push({
                to_: tx.to_,
                token_id: tx.token_id,
                amount: tx.amount
            })
        })

        if (!(contractAddress in fa2TransferParams)) {
            fa2TransferParams[contractAddress] = [];
        }
        fromSorted.forEach((fromTx, fromAddress) => {
            fa2TransferParams[contractAddress]?.push({
                from_: fromAddress,
                txs: fromTx
            })
        })
    })
    return fa2TransferParams;
}
