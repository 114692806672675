import React, { useState, useEffect } from "react";
import { DistributorProps, DistributorState } from "./types/manual";
import { AlertColor, Tooltip , Snackbar, Alert, Slide} from "@mui/material";
// import { DocumentArrowUpIcon } from "@heroicons/react/24/outline";
import assert from "assert";

export const CSV = (props: DistributorProps) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    color: AlertColor, message: string
  }>({ color: "success", message: "" });
  const [sending, setSending] = useState<boolean>(false);

  const [prev, setPrev] = useState<DistributorState>({
    nftAddress: "",
    nftId: "",
    amountEach: "",
    recipientAddresses: "",
    distributorData: [],
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const reader = new FileReader();
    if (file) {
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv = e.target?.result;
        const rows = csv?.toString().split("\n");
        let data:any[] = [];
        const headers = rows![0].split(",");
        if (headers[0] !== "Sender Address") {
          // alert("Headers are not correct!");
          setSnackbarData({ color: "error", message: `Headers are not correct!` });
          return;
        }
        for (let i = 1; i < rows!.length; i++) {
          const currentline = rows![i].trim().split(",");
          data.push(currentline);
        }
        if (data.length === 0) {
          // alert("Empty CSV file!");
          setSnackbarData({ color: "error", message: `Empty CSV file!` });
          return;
        }
        if (data[0].length !== 4) {
          // alert("Incorrect number of columns!");
          setSnackbarData({ color: "error", message: `Incorrect number of columns!` });
          return;
        }
        if (data[data.length - 1].length === 1 && data[data.length - 1][0] === "") {
          data.pop();
        }
        setPrev({
          ...prev,
          distributorData: data,
        });
      };
    }
  };

  const distribute = async (event: React.FormEvent) => {
    event.preventDefault();
    setSending(true);
    assert(props.airDropper, "Error: Wallet not connected!");
    try {
      const op = await props.airDropper.batchDrop(prev.distributorData);
      setSnackbarData({ color: "info", message: `Distributing NFTs, Please wait for transaction to complete!` });
      await op.confirmation();
      setSnackbarData({ color: "success", message: `NFTs distributed! ${op.opHash}` });
      setSending(false);
    } catch (error: any) {
      console.error(error);
      // alert(error.message);
      setSnackbarData({ color: "error", message: error.message });
      setSending(false);
    }
  };

  useEffect(() => {
    setShowSnackbar(snackbarData.message !== "");
  }, [snackbarData]);

  return (
    <div>
      <h2 className="font-poppins text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
        <span className="block xl:inline">Upload your CSV</span>
      </h2>
      {prev.distributorData.length === 0 && (
        <>
          <Tooltip title={
            <React.Fragment>
              <p>Upload CSV with the following headers:</p>
              <b>Sender Address, Contract Address, Token ID, Recipient Address</b>
            </React.Fragment>
          } placement="bottom">
            <div className="flex items-center justify-center">
              <label className="cursor-pointer relative block w-96 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 m-8">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  type="file"
                  className="hidden"
                  id="csvFileInput"
                  accept={".csv"}
                  onChange={handleOnChange}
                />
                <span>Upload a file</span>
              </label>
            </div>
          </Tooltip>

          <div className="flex items-end justify-center font-bold text-sm tracking-tight text-gray-900 sm:text-2xl">
            <div className="w-96 text-right">
              <a className="block text-sm text-primary/90 hover:opacity-70 active:text-primary/100 xl:inline" href="/template.csv" download="template">Download Template</a>{" "}
            </div>
          </div>

        </>
      )}

      {prev.distributorData.length > 0 && (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="flex flex-row justify-center py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg scrollbar-thin scrollbar-thumb-primary/80 hover:scrollbar-thumb-primary scrollbar-track-light_blue/20 scrollbar-thumb-rounded-[4px] scrollbar-track-rounded-[4px]">
                  <table className="min-w-full px-4 divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Sender's Address
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Token Address
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Token ID
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                        >
                          Recipient Address
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {prev.distributorData.map((data, index) => (
                        <tr key={index}>
                          <td className="text-left px-4">{data[0]}</td>
                          <td className="text-left px-4">{data[1]}</td>
                          <td className="text-left px-4">{data[2]}</td>
                          <td className="text-left px-4">{data[3]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="m-4 mt-8">
                  {/* tooltip icon to add */}
                  <Tooltip title={
                    <React.Fragment>
                      <p>Upload CSV with the following headers:</p>
                      <b>Sender Address, Contract Address, Token ID, Recipient Address</b>
                    </React.Fragment>
                  } placement="bottom">
                    <label className="cursor-pointer inline-flex float-left rounded-md border border-transparent bg-indigo-100 px-1 py-1 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 ">
                      {/* <DocumentArrowUpIcon className=" h-6 w-6" aria-hidden="true" /> */}
                      <input
                        type="file"
                        className="hidden"
                        id="csvFileInput"
                        accept={".csv"}
                        onChange={handleOnChange}
                      />
                      ^
                    </label>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center">
             { 
             !sending ? (
             <button
                type="button"
                className="rounded-[10px] border border-transparent bg-primary w-40 hover:bg-dark_blue px-3 py-3 text-sm font-medium text-white shadow-sm focus:outline-none mt-8"
                onClick={props.address ? distribute : props.toggleWallet}
              >
                {props.address ? 'Distribute' : 'Connect Wallet'}
              </button> ) :
              (<>
                   <button
                    className="rounded-[10px] border border-transparent w-40 bg-primary px-3 py-2 text-sm font-medium text-white shadow-sm focus:outline-none mt-8 cursor-not-allowed"
                    disabled
                  >
                    <span className="loader-small"></span>
                  </button>
              </>)
              
            }
            </div>
          </div>
        </div>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={(props) => <Slide {...props} direction="right" />}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarData.color}
          sx={{ width: "100%" }}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CSV;
