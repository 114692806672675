import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget1,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  StatisticsWidget5,
  TablesWidget10,
  TablesWidget5,
} from '../../../_metronic/partials/widgets'
import { Link } from 'react-router-dom'

const DashboardPage = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6'>
        <Link to='/airdropper/manual'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='basket'
            color='primary'
            iconColor='white'
            title='AirDropper'
            description='Seamlessly Airdrop Tezos NFTs to your community!'
            titleColor='white'
            descriptionColor='white'
          />
        </Link>
      </div>

      <div className='col-xl-6'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='element-11'
          color='bosy-white'
          iconColor='primary'
          title='More Products'
          description='Coming Soon..'
          titleColor='gray-900'
          descriptionColor='gray-400'
        />
      </div>

      {/* <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='left'
          color='dark'
          iconColor='gray-100'
          title='Sales Stats'
          description='50% Increased for FY20'
          titleColor='gray-100'
          descriptionColor='gray-100'
        />
      </div> */}
    </div>
    {/* end::Row */}

   

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-8'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
    </div> */}
    {/* end::Row */}
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
