import { NetworkType } from "@airgap/beacon-types";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { TezosToolkit, OpKind, WalletParamsWithKind } from "@taquito/taquito";
import { TransferParamType, TxType } from "../types/air-dropper";
import { parseCSV } from "../utils/csv";
import { createTransferParams, shuffleArray } from "../utils/manual";
import { TokenManualState } from "../types/data";
import { Token } from "../types/api";

export class AirDropper {
  private tezos: TezosToolkit = new TezosToolkit(
    process.env.REACT_APP_PUBLIC_RPC_URL!
  );

  private async batchTx(transferParams: Record<string, TransferParamType[]>) {
    const batchParams: WalletParamsWithKind[] = [];
    for (const contractAddress in transferParams) {
      const contract = await this.tezos.wallet.at(contractAddress);
      const operations = contract.methods
        .transfer(transferParams[contractAddress])
        .toTransferParams();
      batchParams.push({
        kind: OpKind.TRANSACTION,
        ...operations,
      });
    }
    const batch = this.tezos.wallet.batch(batchParams);
    const operation = await batch.send();
    // await operation.confirmation();
    return operation;
  }

  public async getActiveAccount() {
    const wallet = new BeaconWallet({
      name: "Air Dropper",
      appUrl: process.env.PUBLIC_URL,
      preferredNetwork: NetworkType.MAINNET,
    });
    this.tezos.setWalletProvider(wallet);
    const activeAccount = await wallet.client.getActiveAccount();
    return activeAccount;
  }

  public async setWalletProvider() {
    const wallet = new BeaconWallet({
      name: "Air Dropper",
      appUrl: process.env.PUBLIC_URL,
      preferredNetwork: NetworkType.MAINNET,
    });
    this.tezos.setWalletProvider(wallet);

    const activeAccount = await wallet.client.getActiveAccount();
    if (activeAccount) {
      return activeAccount.address;
    }
    if (!activeAccount) {
      await wallet.requestPermissions({
        network: {
          type: NetworkType.MAINNET,
          rpcUrl: process.env.REACT_APP_PUBLIC_RPC_URL,
        },
      });
    }

    return await wallet.getPKH();
  }

  public async disconnectWallet() {
    const wallet = new BeaconWallet({
      name: "Air Dropper",
      appUrl: process.env.PUBLIC_URL,
      preferredNetwork: NetworkType.MAINNET,
    });
    this.tezos.setWalletProvider(wallet);
    await wallet.client.clearActiveAccount();
  }

  public async drop(tokens: TokenManualState[], recipients: string[]) {
    shuffleArray(tokens);
    shuffleArray(recipients);

    for (const token of tokens)
      token.balance = token.selected;

    const transferParams = createTransferParams(
      tokens,
      recipients,
      await this.tezos.wallet.pkh()
    );

    return await this.batchTx(transferParams);
  }

  public async batchDrop(csvData: string[][]) {
    const fa2TransferParams = parseCSV(csvData);
    return await this.batchTx(fa2TransferParams);
  }
}
