import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { DistributorProps } from "./types/manual";
import {
  BiSolidCloudUpload,
  BiSolidNotepad,
} from "react-icons/bi";

import AddressSelector from "./dropdown";
import { getTokensByAddress, getTokensCountByAddress } from "./lib/api";
// import { extractAddresses } from "../../Data/utils/manual";
import Masonry from "@mui/lab/Masonry";
import { Alert, AlertColor, Slide, Snackbar } from "@mui/material";
import { TokenManualState } from "./types/data";
import assert from "assert";
import { Variants, motion } from "framer-motion";
import { extractAddresses } from "./utils/manual";
const NFTCards = lazy(() => import('./NFTCards'));

export const Manual = (props: DistributorProps) => {
  const [selectedCards, setSelectedCards] = useState<number>(0);
  const [recipientAddresses, setRecipientAddresses] = useState<string[]>([]);
  const [recipientField, setRecipientField] = useState<string>("");
  const [tokenCount, setTokenCount] = useState<number>(0);
  const [isInputValid, setInputValid] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("Contract Address");
  const [tokens, setTokens] = useState<TokenManualState[]>([]);
  const [collectionAddresses, setCollectionAddresses] = useState<string[]>([]);
  const [fetchingFlag, setFetchingFlag] = useState<boolean>(false);
  const [wishToContinueFlag, setWishToContinueFlag] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [renderCards, setRenderCards] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sending, setSending] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbarData, setSnackbarData] = useState<{
    color: AlertColor, message: string
  }>({ color: "success", message: "" });

  //onhover suggest text feature

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const distribute = (event: React.FormEvent) => {
    setSending(true);
    console.log(tokens.filter(token => token.selected), recipientAddresses,"inside drop op")
    assert(props.airDropper, "Error: Wallet not connected!");
    const dropOp = props.airDropper
      .drop(tokens.filter(token => token.selected), recipientAddresses)
      .then((operation) => {
        setSnackbarData({ color: "info", message: `Distributing NFTs, Please wait for transaction to complete!` });
        operation.confirmation()
          .then(() => {
            setSending(false);
            setSnackbarData({ color: "success", message: `NFTs distributed! ${operation.opHash}` });
            setRecipientField("");
            getNFTs();
            setCurrentPage(1);
          })
      })
      .catch((error: Error) => {
        setSending(false);
        console.error(error);
        setSnackbarData({ color: "error", message: error.message });
      });

  };

  const getNFTs = async () => {
    if (props.address) {
      setFetching(true);
      const userTokens = await getTokensByAddress(props.address);
      const userTokensCount = await getTokensCountByAddress(props.address);
      console.log(userTokensCount, "userTokensCount")
      setCollectionAddresses(userTokens.collectionAddresses);
      setTokenCount(userTokensCount.totalBalance)
      console.log(userTokens.totalBalance, "balance log")
      setTokens(userTokens.tokens.map((token) => { return { ...token, isShown: true, selected: 0 } }));
      setFetching(false);
    } else {
      setCollectionAddresses([]);
      setTokens([]);
    }
  };

  useEffect(() => {
    setTimeout(() => { setRenderCards(!fetching) }, 20);
  }, [fetching]);

  useEffect(() => {
    setFetchingFlag(true);
    try {
      getNFTs();
      console.log("get NFT Triggered")
    } catch (error) {
      console.log(error);
    }
    setFetchingFlag(false);
  }, [props.address]);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      // handle the file upload here
      console.log(event.target.files);
      const file = event.target.files[0];
      event.target.value = "";
      if (!file) return;
      const fileContent = await file.text();
      setRecipientField(fileContent);
    }
  };

  const handleSelectAll = () => {
    setTokens((prevTokens) => {
      return prevTokens.map((prevToken) => {
        
        
          return { ...prevToken, selected: prevToken.isShown ? prevToken.balance : prevToken.selected};
      });
    });
  };
  

  const handleClear = () => {
    setTokens((prevTokens) => {
      return prevTokens.map((prevToken) => {
        return { ...prevToken, selected: 0 };
      });
    });
  };

  const handleReset = () => {
    setSelectedAddress("Contract Address");
    console.log("Dropdown input cleared.");
  };

  const pasteClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setRecipientField(text);
  };

  const handleRecipientsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRecipientField(event.target.value);
    console.log(event.target.value);
  };

  useEffect(() => {
    setTokens((prevTokens) => {
      return prevTokens.map((prevToken) => {
        return {
          ...prevToken,
          isShown: selectedAddress === "Contract Address" ?
            true :
            prevToken.address === selectedAddress,
        };
      });
    });
  }, [selectedAddress]);

  useEffect(() => {
    const extractedAddresses = extractAddresses(recipientField);
    setRecipientAddresses(extractedAddresses);
  }, [recipientField]);

  useEffect(() => {
    const totalSelected = tokens.reduce((acc, token) => acc + token.selected, 0);
    console.log(totalSelected, "totalSelected")
    setSelectedCards(totalSelected);
    setInputValid(recipientAddresses.length === totalSelected);
  }, [recipientAddresses, tokens]);

  useEffect(() => {
    setShowSnackbar(snackbarData.message !== "");
  }, [snackbarData]);

  //animating wallet button

  const buttonVariants: Variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    poppedUp: {
      opacity: 1,
      y: -3,
      transition: { duration: 0.3 },
    },
  };



  //pagination

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;

  const visibleTokens = tokens
    .filter((token) => {
      return (
        selectedAddress === "Contract Address" ||
        token.address === selectedAddress
      );
    })
    .map((token, index) => {
      const isTokenInCurrentPage =
        index >= startIndex && index < startIndex + itemsPerPage;
      return { ...token, isShown: isTokenInCurrentPage };
    });

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedAddress]);


  const Pagination = ({
    visibleTokens,
    itemsPerPage,
    currentPage,
    onPageChange
  }) => {
    const totalTokens = visibleTokens.length;
    const totalPages = Math.ceil(totalTokens / itemsPerPage);

    const handlePageClick = (page) => {
      if (page >= 1 && page <= totalPages) {
        onPageChange(page);
      }
    };

    const maxPagesToShow = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    return (
      <div className="pagination-footer" style={{ marginTop: "auto" }}>
        <ul className="pagination py-2 px-6" style={{ justifyContent: "space-between" }}>
          <div className="text" style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <span className="text-gray-900 text-lg">
              Showing {currentPage * itemsPerPage - itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, totalTokens)} of {totalTokens} entries
            </span>
          </div>
          <div className="pages" style={{ display: "flex" }}>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => handlePageClick(1)}>
                {"<<"}
              </a>
            </li>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                {"<"}
              </a>
            </li>
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
              <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                <a href="#" className={`page-link ${currentPage === page ? 'page-link-current' : ''}`} onClick={() => handlePageClick(page)}>
                  {page}
                </a>
              </li>

            ))}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                {">"}
              </a>
            </li>
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => handlePageClick(totalPages)}>
                {">>"}
              </a>
            </li>
          </div>
        </ul>
      </div>
    );
  };




  return (
    <div className="flex flex-col h-full">
      <div>
        <div className="flex flex-col md:flex-row gap-2 font-poppins justify-between">
          <div className="flex md:w-3/4 flex-col bg-white bg-opacity-[8%] rounded-[10px] mb-6 md:mb-0" style={{ height: "fit-content", minHeight: "480.5px" }}>
            <div className="flex justify-between">
              <span className="font-poppins font-semibold font-weight-600 text-xl text-left md:text-center lg:text-start px-6 py-8 md:px-8 md:py-6">
                Select your tokens to transfer
              </span>
              <motion.button
                onClick={props.toggleWallet}
                whileHover="poppedUp"
                initial="hidden"
                animate="visible"
                variants={buttonVariants}
                style={{
                  width: "130px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="rounded-[5px] text-white text-center bg-primary px-3 my-2 mx-8 mt-6 mb-8"              >
                {props.address && (
                  <>
                    <motion.div
                      initial={{ y: 50 }}
                      animate={isHovered ? { y: 0 } : { y: 50 }}
                      exit={{ y: 50 }}
                      transition={{ duration: 0.2, type: "tween", ease: "easeInOut" }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Disconnect Wallet
                    </motion.div>

                    <motion.div
                      initial={{ y: 0 }}
                      animate={isHovered ? { y: -50 } : { y: 0 }}
                      exit={{ y: -50 }}
                      transition={{ duration: 0.2, type: "tween", ease: "easeInOut" }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >

                      {props.address.slice(0, 9) + "...." + props.address.slice(32, 36)}
                    </motion.div>
                  </>
                )}
                {!props.address && <span> Sync Wallet</span>}
              </motion.button>
            </div>


            <div className="flex flex-col gap-2">

              <div className="flex px-6 md:px-[30px] gap-4 justify-between  font-poppins font-semibold flex-col lg:flex-row lg:text-sm xl:text-base">
                <div className="flex gap-4 justify-center flex-row ">
                  <span className="mt-1">Filter by</span>
                  <AddressSelector
                    tokens={tokens}
                    contractAddresses={collectionAddresses}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                  />
                  {selectedAddress !== "Contract Address" && (
                    <button
                      onClick={handleReset}
                      className="text-[#D72F2F] hover:text-red-500"
                    >
                      Reset
                    </button>
                  )}
                </div>

                <div className="flex justify-center flex-row gap-4 px-4">
                  <span className="mt-1">{selectedCards} - selected</span>

                  <button
                    onClick={handleSelectAll}
                    className="hover:text-primary"
                  >
                    Select all
                  </button>
                  <span className="text-primary mt-1">|</span>
                  <button
                    onClick={handleClear}
                    className="text-[#D72F2F] hover:text-red-500"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div>
                <div className="mx-5 mt-2">
                  <div className="mx-auto font-syne">
                    {props.address ? (
                      !fetching ? (
                        collectionAddresses.length > 0 ? (
                          fetchingFlag && visibleTokens ? (
                            <p>"Fetching"</p>
                          ) : (
                            <Masonry columns={{ lg: 5, sm: 2, md: 4, xs: 1 }} spacing={2}>
                              {visibleTokens.map((token, index) => token.isShown && (
                                <Suspense key={index} fallback={
                                  <div className={`mb-4 ${(!renderCards) && 'hidden'}`}>
                                    <div
                                      className={`bg-white  ring-1 ring-black/5 active:bg-light_blue active:text-white hover:ring-dark_blue rounded-[6px] justify-center flex flex-col p-[5px] px-[6px] break-inside-avoid max-w-[200px] max-h-[400px] lg:max-w-[350px] lg:max-h-[500px] overflow-hidden`}
                                      style={{ margin: "0 auto" }}
                                    >
                                      <div className="min-h-[100px]" style={{ position: "relative", overflow: "hidden", borderRadius: "4px" }}>
                                        <div className="animate-pulse bg-gray-300 rounded-[4px] w-full aspect-square" style={{ position: "absolute" }} />
                                      </div>
                                      <div className="flex py-1.5 divide-gray-300 px-1 divide-y justify-start flex-col">
                                        <div className="flex flex-row text-[14px] pb-2 justify-between">
                                          <h1 className="truncate">
                                            {"Loading..."}
                                          </h1>
                                        </div>
                                        <div className="flex text-[14px] justify-between flex-row pt-3">
                                          <span className="bg-purple-300  rounded-md px-1.5 py-0.5">
                                            #0000
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }>
                                  <div className={`${(!renderCards) && 'hidden'}`}>
                                    <NFTCards token={token} tokensLength={visibleTokens.length} setTokens={setTokens} />
                                  </div>
                                </Suspense>
                              ))}
                            </Masonry>
                          )
                        ) : (
                          <div className="w-[100%] flex justify-center">
                            <div className="flex-col md:flex-row gap-8 py-6 font-poppins justify-between mx-4 pb-10">
                              <div className="py-24 font-poppins text-center flex gap-3 flex-col justify-center">
                                <span className="text-gray-800 font-semibold text-xl">
                                  No NFTs found in your wallet : (
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="w-[100%] flex justify-center">
                          <div className="flex-col md:flex-row gap-8 py-6 font-poppins justify-between mx-4 ">
                            <div className="py-40 font-poppins text-center flex gap-3 flex-col justify-center">
                              <div>
                                {" "}
                                <span className="loader"></span>
                              </div>
                              <span className="text-gray-800 font-semibold text-xl">
                                Fetching your NFTs...{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      )) : (
                      <div className="w-[100%] flex justify-center pt-5 pb-20">
                        <div className="flex-col md:flex-row gap-8 py-6 font-poppins justify-between mx-4 ">
                          <div className="py-24 font-poppins text-center flex gap-3 flex-col justify-center">
                            <span className=" font-semibold text-xl">
                              Connect your wallet to view your NFTs : )
                            </span>
                            <motion.button

                              onClick={props.toggleWallet}
                              whileHover="poppedUp"
                              initial={{ y: 2 }}
                              variants={{
                                poppedUp: {
                                  opacity: 1,
                                  y: 0,
                                  scale: 1.03,
                                  transition: { duration: 0.1 },
                                },
                              }}
                              className="bg-primary hover:bg-primary/80 active:bg-primary text-white font-medium rounded-[10px] py-3 px-6 my-3 mx-auto cursor-pointer "
                            >
                              Get Started
                            </motion.button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {props.address && (
              <Pagination
                visibleTokens={visibleTokens}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
          <div>
            <div className="lg:min-w-[300px] md:w-[260px] bg-white bg-opacity-[8%] lg:max-w-[380px] rounded-[10px] p-6 space-y-6 sticky top-0">


              <div className="flex gap-2 flex-col">
                <span className="font-poppins font-semibold text-black text-left md:text-start text-xl">
                  Recipients
                </span>
                <span className="font-poppins font-semibold text-left md:text-start text-[14px] py-7">
                  Drop a list of recipients
                </span>
              </div>
              <div className="w-full flex flex-col rounded-[10px] bg-white space-y-2  ring-1 focus-within:border-indigo-800 focus-within:ring-1 focus-within:ring-dark_blue">
                <textarea
                  onChange={handleRecipientsChange}
                  placeholder="tz1...xyz , tz2...xyz , ..."
                  className="h-[110px] min-h-[40px] w-full p-2 border-none focus:ring-0 focus:outline-none text-[12px] rounded-[10px] shadow-none scrollbar-thin scrollbar-thumb-primary/80 hover:scrollbar-thumb-primary scrollbar-track-light_blue/20 scrollbar-thumb-rounded-[4px] scrollbar-track-rounded-[4px]"
                  id="recipientsInputText"
                  value={recipientField}
                />

                <div className="flex flex-row justify-end gap-2 text-[10px] pr-3">
                  <button
                    className=""
                    onClick={(e) => {
                      e.preventDefault();
                      fileInputRef.current?.click();
                    }}
                    id="csv"
                  >
                    <div className="flex  gap-1 flex-row">
                      <BiSolidCloudUpload className="mt-1.5" />
                      <span className="my-1">Upload a file</span>
                    </div>
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".csv, .txt, .json, .xlsx, .xls, .tsv, .ods, .numbers"
                    onChange={handleFileUpload}
                  />
                  <span className="text-[#7028E4] my-1">|</span>
                  <button id="paste-btn" onClick={pasteClipboard}>
                    <div className="flex gap-1 flex-row">
                      <BiSolidNotepad className="mt-1.5" />
                      <span className="my-1">Paste</span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="font-semibold font-poppins flex flex-row justify-between w-full text-[14px] px-3">
                <span>Total recipients</span>
                <span>{recipientAddresses.length}</span>
              </div>
              {selectedCards === 0 ? (
                <div className="bg-[#FFC6C6] text-sm text-start p-2 px-4 rounded-[10px]">
                  You have not selected any NFT.
                </div>
              ) : recipientAddresses.length === 0 ? (
                <div className="bg-[#FFC6C6] text-sm text-start p-2 px-4 rounded-[10px]">
                  You have not added any addresses.
                </div>
              ) : (
                <div
                  className={
                    isInputValid
                      ? "bg-green-100 text-sm text-start p-2 rounded-[10px] px-4"
                      : "bg-[#FFC6C6] text-sm text-start p-2 rounded-[10px] px-4"
                  }
                >
                  {`You selected ${selectedCards} NFT${selectedCards > 1 ? "s" : ""
                    } \
                          and added ${recipientAddresses.length} recipient${recipientAddresses.length > 1 ? "s" : ""
                    }. ` +
                    (isInputValid
                      ? recipientAddresses.length === 1
                        ? "The recipient will receive the selected NFT."
                        : "All the recipients will receive one random NFT each."
                      : selectedCards > recipientAddresses.length
                        ? `${selectedCards - recipientAddresses.length
                        } random NFT${selectedCards - recipientAddresses.length >
                          1
                          ? "s"
                          : ""
                        } will be left in your wallet.`
                        : `${recipientAddresses.length - selectedCards
                        } random recipient${recipientAddresses.length - selectedCards >
                          1
                          ? "s"
                          : ""
                        } will not receive anything.`)}
                </div>
              )}
              <div className="font-semibold flex items-center gap-2 text-[14px]">
                <input
                  type="checkbox"
                  className="bg-[#fffff] hover:bg-primary/25 outline-none border-grey rounded-[5px] focus:outline-none focus:ring-0"
                  value={wishToContinueFlag.toString()}
                  onChange={() =>
                    setWishToContinueFlag(!wishToContinueFlag)
                  }
                />
                <span>I wish to continue</span>
              </div>
              {
                !sending ? (
                  <button
                    className={
                      wishToContinueFlag &&
                        recipientAddresses.length > 0 &&
                        selectedCards > 0
                        ? "rounded-[10px] text-white p-3 bg-primary hover:bg-dark_blue w-full"
                        : "rounded-[10px] text-white p-3 bg-primary w-full bg-opacity-50 cursor-not-allowed"
                    }
                    onClick={distribute}
                    disabled={
                      !wishToContinueFlag ||
                      recipientAddresses.length === 0 ||
                      selectedCards === 0
                    }
                  >
                    Send
                  </button>
                ) : (
                  <button
                    className="relative rounded-[10px] text-white p-3 bg-primary w-full bg-opacity-50 cursor-not-allowed"
                    disabled
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <span className="loader-small" style={{ width: "20px", height: "20px", marginRight: "5px", alignSelf: "center" }}></span>
                    Sending...
                  </button>
                )
              }

            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={(props) => <Slide {...props} direction="right" />}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarData.color}
          sx={{ width: "100%" }}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
    </div >
  );
};

export default Manual;