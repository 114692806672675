import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Navigate, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword, resetPassword, verifyEmail} from '../core/_requests'

// const initialValues = {
//   password: '',
//   changepassword: '',
// }

// const resetPasswordSchema = Yup.object().shape({
//   password: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
//   changepassword: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password confirmation is required')
//     .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
// })

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export function ConfirmEmail() {
  const query = useQuery();
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('');
  const [message, setMessage] = useState<string | undefined>('');
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    const tokenParam = query.get('token');
    if (tokenParam) {
      setToken(tokenParam);
      console.log(tokenParam,"tokenParam")
        verifyEmail(tokenParam).then((res) => {
            console.log(res,"res")
            if (res) {
                console.log(res)
                setMessage(res.data.message)
                setHasErrors(false)
                setLoading(false)
            }
            else {
                console.log(res)
                setHasErrors(true)
                setLoading(false)
            }
        })
        }
  }, [query]);



  

//   const formik = useFormik({
//     initialValues,
//     validationSchema: resetPasswordSchema,
//     onSubmit: (values, {setStatus, setSubmitting}) => {
//       setLoading(true)
//       setHasErrors(undefined)
//       setTimeout(() => {
//         console.log(values,token,"reset-passs")
//         if(!token){
//             setHasErrors(true)
//             setLoading(false)
//             setSubmitting(false)
//             setStatus('Token Not Found')
//         }
//         const res = resetPassword(values.password,token).then((res) => {return res.data})
//         console.log(res,"res")
//         if (res) {
//             console.log(res)
//             setHasErrors(false)
//             setLoading(false)
//         }
//         else {
//             console.log(res)
//             setHasErrors(true)
//             setLoading(false)
//             setSubmitting(false)
//             setStatus('The login detail is incorrect')
//         }
//           // .then(({data: {result}}) => {
//           //   console.log(result)
//           //   setHasErrors(false)
//           //   setLoading(false)
//           // })
//           // .catch(() => {

//           //   setHasErrors(true)
//           //   setLoading(false)
//           //   setSubmitting(false)
//           //   setStatus('The login detail is incorrect')
//           // })
//       }, 1000)
//     },
//   })

  return (
   <>
         
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'
      style={{flexDirection:"column",alignContent:"center"}}>
          <br />
        
            {loading ? 
            <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>
                    Please wait while we verify your email.
                </div>
            </div>
            : 
            <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>
                    {message}
                </div>
            </div>
            }
        
        <Link to='/auth/login' style={{margin:"auto"}}>
            <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
            >
                Back to Login
            </button>
        </Link>{' '}
        </div>
    </>
  )
}
