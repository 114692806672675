import { TokenData } from "../types/api";

// const tzktApiUrl = process.env.REACT_APP_TZKT_API_URL!;
const tzktApiUrl = "https://api.tzkt.io";

export const getTokensByAddress = async (
  address: string,
): Promise<TokenData> => {
  console.log(tzktApiUrl,"tzktApiUrl");
  const response = await fetch(
    `${tzktApiUrl}/v1/tokens/balances?` +
      new URLSearchParams({
        account: address,
        "token.standard": "fa2",
        "balance.gt": "0",
        "token.metadata.artifactUri.null": "false",
        limit: "10000",
        // offset: `${(pageNo-1)*10}`,
        "token.metadata.decimals": "0",
        "sort.desc": "lastLevel",
        select: [
          "balance",
          "token.contract.address",
          "token.tokenId",
          "token.metadata.name",
          "token.metadata.symbol",
          "token.metadata.decimals",
          "token.metadata.displayUri",
          "token.metadata.artifactUri",
          "token.metadata.thumbnailUri",
        ].join(","),
      })
  );

  if (response.ok) {
    const data: Record<string, string>[] = await response.json();
    const collectionAddresses: string[] = [];
    data.forEach((token) => {
      if (!collectionAddresses.includes(token["token.contract.address"])) {
        collectionAddresses.push(token["token.contract.address"]);
      }
    });
    const tokens = data.map((token) => {
      return {
        balance: parseInt(token["balance"]),
        tokenId: parseInt(token["token.tokenId"]),
        address: token["token.contract.address"],
        metadata: {
          name: token["token.metadata.name"],
          symbol: token["token.metadata.symbol"],
          decimals: parseInt(token["token.metadata.decimals"]),
          displayUri: token["token.metadata.displayUri"],
          artifactUri: token["token.metadata.artifactUri"],
          thumbnailUri: token["token.metadata.thumbnailUri"],
        },
        imageUrl: "",
      };
    });
    const totalBalance = tokens.reduce((acc, token) => acc + token.balance, 0);
    return {
      collectionAddresses,
      tokens,
      totalBalance,
    };
  } else {
    throw new Error(
      `Error fetching tokens for address ${address}: ${response.statusText}`
    );
  }
};
export const getTokensCountByAddress = async (
  address: string,
): Promise<any> => {
  console.log(tzktApiUrl,"tzktApiUrl");
  const response = await fetch(
    `${tzktApiUrl}/v1/tokens/balances/count?` +
      new URLSearchParams({
        account: address,
        "token.standard": "fa2",
        "balance.gt": "0",
        "token.metadata.artifactUri.null": "false",
        "token.metadata.decimals": "0",
      })
  );
  console.log("response not ok",response);
  
  if (response.ok) {
    const totalBalance =  await response.json();
    console.log(totalBalance,response)
    return {
      totalBalance
    };
  } else {
    throw new Error(
      `Error fetching tokens count ${address}: ${response}`
    );
  }
};
