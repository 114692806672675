/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import noUiSlider, {target} from 'nouislider'
import {useLayout} from '../../core'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {DefaultTitle} from './page-title/DefaultTitle'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'

const HeaderToolbar = () => {
  const {classes} = useLayout()
  const [status, setStatus] = useState<string>('1')

  useEffect(() => {
    const slider: target = document.querySelector('#kt_toolbar_slider') as target
    const rangeSliderValueElement: Element | null = document.querySelector(
      '#kt_toolbar_slider_value'
    )

    if (!slider) {
      return
    }

    slider.innerHTML = ''

    noUiSlider.create(slider, {
      start: [5],
      connect: [true, false],
      step: 1,
      range: {
        min: [1],
        max: [10],
      },
    })

    slider.noUiSlider?.on('update', function (values: any, handle: any) {
      if (!rangeSliderValueElement) {
        return
      }

      rangeSliderValueElement.innerHTML = parseInt(values[handle]).toFixed(1)
    })
  }, [])

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        <div className='d-flex align-items-stretch  pt-3 pt-lg-0'>
         


          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Label */}
            {/* <span className='fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block'>
              Quick Tools:
            </span> */}
            {/* end::Label */}

            {/* begin::Theme mode */}
            {/* <div className='d-flex align-items-center'>
              <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary mt-n2' />
            </div> */}
              {/* end::Theme mode */}
            {/* begin::Actions */}
            {/* <div className='d-flex'> */}
              {/* begin::Action */}
              
              {/* end::Action */}



              {/* begin::Notifications */}
              <div className='d-flex align-items-center'>
                  {/* begin::Menu- wrapper */}
                  <div className=''>
                  {/*begin::Action*/}
                  <a
                    href='#'
                    className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-start'
                    data-kt-menu-overflow='false'
                  >
                    {/* <KTIcon iconName='setting-2' className='text-muted fs-1' /> */}
                    <div className='symbol symbol-40px'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank-color.png')} />
                </div>
                  </a>

                <HeaderUserMenu />
                {/*end::Action*/}
              {/* </div> */}
                {/* end::Menu wrapper */}
              </div>
              {/* end::Notifications */}


            
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  )
}

export {HeaderToolbar}
