import React, { useState, useEffect, useRef } from "react";
import { Token } from "./types/api";

const AddressSelector = ({
  tokens,
  contractAddresses,
  selectedAddress,
  setSelectedAddress,
}: {
  tokens: Token[];
  contractAddresses: string[];
  selectedAddress: string;
  setSelectedAddress: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [balance, setBalance] = useState({} as Record<string, number>);
  const [searchAddress, setSearchAddress] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const balance = {} as Record<string, number>;
    for (const address of contractAddresses) {
      balance[address] = 0;
    }

    for (const token of tokens) {
      if (balance[token.address] !== undefined) {
        balance[token.address]++;
      }
    }

    contractAddresses.sort((a,b) => Number(balance[a]) - Number(balance[b]));
    console.log('Balance:', balance)
    contractAddresses.reverse();
    setBalance(balance);
  }, [tokens, contractAddresses]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const truncateAddress = (address: string) => {
    return address
      ? address.substring(0, 6) + "..." + address.substring(address.length - 6)
      : "";
  };

  const isDropdownAvailable = contractAddresses.length > 0;


  const handleOnClick = async (event: React.MouseEvent) => {
    const text = await navigator.clipboard.readText();
    if (/KT1[1-9A-HJ-NP-Za-km-z]{33}/.test(text)) {
      setSelectedAddress(text);
    }
    setSearchAddress(text);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchAddress(event.target.value);
  };

  useEffect(() => {
    setSearchAddress(selectedAddress === "Contract Address" ? "" : selectedAddress);
  }, [selectedAddress])

  return (
    <div ref={ref} className="relative inline-block text-left">
      <span className="rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => isDropdownAvailable && setIsOpen(!isOpen)} // Check if the dropdown is available
          className={`bg-primary hover:bg-dark_blue flex justify-center items-center text-center flex-row text-white text-[12px] px-[14px] py-[3px] rounded-[10px] ${!isDropdownAvailable && "opacity-50 cursor-not-allowed"
            }`}
          disabled={!isDropdownAvailable}
        >
          {selectedAddress === "Contract Address" ? (
            <span className="pr-[3px]">Contract Address</span>
          ) : (
            <span className="font-mono"> {truncateAddress(selectedAddress)}</span>
          )}

          {!isOpen ? (
            <div className="px-1 rotate-90">&gt;</div>
          ) : (
            <div className="px-1 -rotate-90">&gt;</div>
          )}
        </button>
      </span>
      {isOpen && (
        <div className="origin-top-center absolute mt-2.5 w-56 shadow-lg z-10 animate-in fade-in animate-out fade-out duration-500 ">
          <div>
            <div className=" bg-white divide-y-2 px-5  divide-solid divide-primary rounded-[10px] max-w-[250px] p-2 gap-1 shadow-xs justify-center flex flex-col">
              <div className="flex pt-1 flex-row justify-center">
                <input
                  placeholder="Paste Contract Address"
                  value={searchAddress}
                  className="border-ring-primary bg-light_blue bg-opacity-50 rounded-[10px] px-2 py-1 text-center text-dark_blue text-[12px] w-[180px] placeholder-dark_blue focus:placeholder-opacity-30 hover:bg-primary hover:bg-opacity-20 focus-within:border-primary focus-within:ring-1 focus-within:ring-primary"
                  onClick={handleOnClick}
                  onChange={handleOnChange}
                />
              </div>
              <div className="flex justify-center pt-4 flex-col overflow-y-auto max-h-[400px] scrollbar-thin scrollbar-thumb-primary/80 hover:scrollbar-thumb-primary scrollbar-track-light_blue/20 scrollbar-thumb-rounded-[4px] scrollbar-track-rounded-[4px]">
                {contractAddresses.map((address) => (
                  <button
                    key={address}
                    type="button"
                    className="p-2 outline-none text-[12px] rounded-[5px] text-justify font-mono hover:bg-light_blue hover:bg-opacity-50 "
                    onClick={() => {
                      setSelectedAddress(address);
                      setIsOpen(false);
                    }}
                  >
                    <div className="flex justify-between">
                      <div>
                        {address.substring(0, 5) + "..." + address.substring(28, 36)}
                      </div>
                      <div>
                        {balance[address]}
                      </div>
                    </div>
                  </button>
                ))}

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressSelector;
