import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const API_URL = "http://localhost:3000"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/change-password/`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const INIT_VERIFY = `${API_URL}/auth/verify-email`

// Server should return AuthModel
export async function login (email: string, password: string) {
  console.log(email,password,LOGIN_URL,"username,password,link")
  const res = await axios.post(LOGIN_URL, {
    "username":email,
    password,
  })
  console.log(res,"res")
  const auth:AuthModel = {
    api_token: res.data.data.accessToken.accessToken || "",
  }
  return auth
}

// Server should return AuthModel
export function register(
  email: string,
  username: string,
  firstname: string,
  lastname: string,
  password: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    username,
    password,
    "firstName": firstname,
    "lastName": lastname,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: any}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function resetPassword(password: string, token: string) {
  return axios.post<{result: any}>(RESET_PASSWORD_URL, {
    token,
    "newPassword": password,
  })
}
export function changePassword(oldPassword: string,newPassword: string, token: string) {
  console.log(oldPassword,newPassword,token,"oldPassword,newPassword,token")
  return axios.post<{result: any}>(CHANGE_PASSWORD_URL, {
    oldPassword,
    newPassword
  },
  {
    headers: { Authorization: `Bearer ${token}` }
  })
}
export function initVerify(token: string) {
  return axios.post<{result: any}>(INIT_VERIFY,null, {
    headers: { Authorization: `Bearer ${token}` }
  })
}
export function verifyEmail(token: string) {
  return axios.get(INIT_VERIFY+`/?token=${token}`)
}



export async function getUserByToken(token: string) {
  console.log(token,"token")  
  const res = await axios.get(GET_USER_BY_ACCESSTOKEN_URL, { headers: { Authorization: `Bearer ${token}` } })
  const user:UserModel = {
    id: res.data.data.id,
    username: res.data.data.username,
    password: undefined,
    email: res.data.data.email,
    first_name: res.data.data.firstName,
    last_name: res.data.data.lastName,
  }
  console.log(user,"user")
  return user
}
