import { useIntl } from "react-intl"
import { PageTitle } from "../../../_metronic/layout/core"
import DApp from "./dApp"
// import { BreadcrumbItem } from "react-bootstrap"

const ManualWrapper = () => {
    const intl = useIntl()
    const BreadcrumbItem =[
      {
        title: "Home",
        path: '/dashboard',
        isSeparator: false,
        isActive: false, 
      },
      {
        title: "Airdropper",
        path: '/airdropper/manual',
        isSeparator: true,
        isActive: true, 
      },
      {
        title: "Airdropper",
        path: '/airdropper/manual',
        isSeparator: false,
        isActive: true, 
      },
      {
        title: "Airdropper",
        path: '/airdropper/manual',
        isSeparator: true,
        isActive: true, 
      },
     
  ]
    return (
      <>
        <PageTitle breadcrumbs={BreadcrumbItem}>{intl.formatMessage({id: 'Manual Drop'})}</PageTitle>
        <DApp screen="manual"/>
      </>
    )
  }
  
  const CSVWrapper = () => {
    const intl = useIntl()
    const BreadcrumbItem =[
      {
        title: "Home",
        path: '/dashboard',
        isSeparator: false,
        isActive: false, 
      },
      {
        title: "Airdropper",
        path: '/airdropper/manual',
        isSeparator: true,
        isActive: true, 
      },
      {
        title: "Airdropper",
        path: '/airdropper/manual',
        isSeparator: false,
        isActive: true, 
      },
      {
        title: "Airdropper",
        path: '/airdropper/CSV',
        isSeparator: true,
        isActive: true, 
      },
     
  ]
    return (
      <>
        <PageTitle breadcrumbs={BreadcrumbItem}>{intl.formatMessage({id: 'CSV Drop'})}</PageTitle>
        <DApp screen="csv" />
      </>
    )
  }
  
  export {ManualWrapper , CSVWrapper}