import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LayoutProvider, LayoutSplashScreen} from './_metronic/layout/core'
import {MasterInit} from './_metronic/layout/MasterInit'
import { I18nProvider } from './_metronic/i18n/i18nProvider'
import { AuthInit } from './app/modules/auth'
import {ThemeModeProvider} from './_metronic/partials'
import './tailwind.css'; 
window.Buffer = window.Buffer || require("buffer").Buffer; 


const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
        <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
        </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export default App
