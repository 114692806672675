import { TransferParamType, TxType } from "../types/air-dropper";
import { Token } from "../types/api";


// find all the tezos addresses (tz1, tz2, tz3) in a string using regex and return them in an array
export const extractAddresses = (data: string): string[] => {
    const tezosWalletRegex = /tz[1-3][1-9A-HJ-NP-Za-km-z]{33}/g;
    const tezosContractRegex = /KT1[1-9A-HJ-NP-Za-km-z]{33}/g;

    const walletMatches = data.match(tezosWalletRegex) || [];
    const contractMatches = data.match(tezosContractRegex) || [];

    const matches = [...walletMatches, ...contractMatches];
    return matches;
};


// return an array of tokens that have the same address as the one provided
export const filterTokensByAddress = (tokens: Token[], address: string): Token[] => {
    const filteredTokens = tokens.filter((token) => token.address === address);
    return filteredTokens;
};


// given a list of tokens and a list of addresses, return a tx params to distribute the tokens to the addresses
export const createTransferParams = (tokens: Token[], recipients: string[], sender: string): Record<string, TransferParamType[]> => {
    // Calculate the total number of tokens to distribute
    const totalTokens = tokens.reduce((acc, token) => acc + token.balance, 0);

    // Initialize an empty result object
    const result: Record<string, TransferParamType[]> = {};

    // Define a helper function to add transfer parameters for a token
    const addTransferParams = (token: Token, amount: number, recipient: string) => {
        const transferParams: TransferParamType = {
            from_: sender,
            txs: [{ to_: recipient, token_id: token.tokenId, amount }]
        };

        if (!result[token.address]) {
            result[token.address] = [transferParams];
        } else {
            result[token.address].push(transferParams);
        }
    };

    // Define a helper function to get random token out of tokens[]
    const getRandomToken = (tokensArray: Token[]): Token => {
        const randomIndex = Math.floor(Math.random() * tokensArray.length);
        console.log(randomIndex);
        tokensArray[randomIndex].balance--;
        if (tokensArray[randomIndex].balance === 0)
            return tokensArray.splice(randomIndex, 1)[0];
        else
            return tokensArray[randomIndex];
    };

    // Distribute the tokens to recipients
    let remainingTokens = totalTokens;
    let currentRecipient = 0;
    let tokensCopy = JSON.parse(JSON.stringify(tokens)); // later shift to structuredClone() when it's supported

    while (currentRecipient < recipients.length && remainingTokens > 0) {

        // Get a random token
        const token = getRandomToken(tokensCopy);

        // Add transfer parameters for the current recipient
        addTransferParams(token, 1, recipients[currentRecipient]);

        // Update remaining tokens and move to the next recipient
        remainingTokens--;
        currentRecipient++;
    }

    return result;
};



// randomly shuffle an array
export const shuffleArray = (array: any[]) => {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}
